import Button from './components/button';
import { useState, Fragment, useEffect } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { QUERY_GET_DOCUMENT_ID } from './graphql/Doc';
import { useLocation } from 'react-router-dom';

// import { useHistory, useLocation, Redirect } from 'react-router';

const style = {
	backgroundImage: `url("/img/background/bg.svg")`,
	backgroundColor: 'white',
	backgroundSize: 'cover',
	backgroundRepeat: 'no-repeat',
	with: 'auto'
};

function Busqueda() {
	const location = useLocation();
	const [ errorMessage, setErrorMessage ] = useState('');
	const [ emisor, setEmisor ] = useState('');
	const [ tipoDoc, setTipoDoc ] = useState('');
	const [ serie, setSerie ] = useState('');
	const [ correlativo, setCorrelativo ] = useState(0);

	const [ buscarDoc, { data, loading, error } ] = useLazyQuery(QUERY_GET_DOCUMENT_ID);

	useEffect(
		() => {
			if (data != undefined) {
				if (data.getIdHistorialComprobanteConsulta == 'NO EXISTE COMPROBANTE') {
					alert(data.getIdHistorialComprobanteConsulta);
				} else {
					const url = 'consulta/' + data.getIdHistorialComprobanteConsulta;
					window.location.href = window.location.href + url;
				}
			}
		},
		[ data ]
	);

	if (loading) return <p>Cargando ...</p>;

	const obtenerInfo = () => {
		let tipo = tipoDoc;
		if (tipoDoc == 'Nota de Credito') {
			if (serie.startsWith('F')) {
				tipo = 'Nota de Crédito F.';
			}
			if (serie.startsWith('B')) {
				tipo = 'Nota de Crédito B.';
			}
		}

		buscarDoc({
			variables: {
				ruc: String(emisor),
				tipoDoc: String(tipo),
				serie: String(serie),
				correlativo: parseInt(correlativo)
			}
		});
	};

	return (
		<div className="flex flex-col h-screen w-full items-center justify-center" style={style}>
			<div className="flex w-40 h-25 justify-center items-center">
				<img className="object-contain object-center w-25 h-15" src="/img/logos/logo.svg" alt="detoddo" />
			</div>

			<div className="flex flex-col w-80">
				<span className="w-full text-center font-semibold text-2xl text-color-19">Consulta de Documentos</span>
				<p className="w-full text-center font-semibold text-red-500">{errorMessage}</p>
				<Fragment>
					<label className="text-color-19 font-normal text-base mt-1">RUC Emisor:</label>
					<input
						id="emisor"
						className="px-3 py-2 mt-1 text-base text-color-1 focus:outline-none border border-gray-400 rounded-lg"
						type="text"
						onChange={(e) => {
							setEmisor(e.target.value);
						}}
						value={emisor}
						required
					/>
				</Fragment>
				<Fragment>
					<label className="text-color-19 font-normal text-base mt-1">Documento:</label>
					<select
						className="px-2 py-1 mt-1 text-color-11 text-xl bg-white font-semibold w-full focus:outline-none border border-color-7"
						name="tipoDoc"
						onChange={(e) => {
							setTipoDoc(e.target.value);
						}}
						value={tipoDoc}
						required
					>
						<option className="text-color-1 font-semibold" value="-">
							Seleccionar
						</option>
						<option className="text-color-1 font-semibold" value="Factura">
							Factura Electronica
						</option>
						<option className="text-color-1 font-semibold" value="Boleta">
							Boleta de Venta Electronica
						</option>
						<option className="text-color-1 font-semibold" value="Nota de Credito">
							Nota de Credito Electronica
						</option>
						<option className="text-color-1 font-semibold" value="Nota de Debito">
							Nota de Debito Electronica
						</option>
					</select>
				</Fragment>
				<Fragment>
					<label className="text-color-19 font-normal text-base mt-1">Serie:</label>
					<input
						id="serie"
						className="px-3 py-2 mt-1 text-base text-color-1 focus:outline-none border border-gray-400 rounded-lg"
						type="text"
						onChange={(e) => {
							setSerie(e.target.value);
						}}
						value={serie}
						required
					/>
				</Fragment>
				<Fragment>
					<label className="text-color-19 font-normal text-base mt-1">Número:</label>
					<input
						id="correlativo"
						className="px-3 py-2 mt-1 text-base text-color-1 focus:outline-none border border-gray-400 rounded-lg"
						type="text"
						onChange={(e) => {
							setCorrelativo(e.target.value);
						}}
						value={correlativo}
						required
					/>
				</Fragment>
				<button
					className="text-white py-3 px-4 border border-color-18 bg-color-18 focus:outline-none mt-4 mb-4 rounded-lg"
					onClick={() => obtenerInfo()}
				>
					Ver Documento
				</button>
			</div>
		</div>
	);
}

export default Busqueda;
