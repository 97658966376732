import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { QUERY_GET_DOCUMENT } from "./graphql/Doc";
import ButtonPDF from "./components/ButtonPDF";

function Consulta() {
  const { doc } = useParams();

  const { data, loading, error } = useQuery(QUERY_GET_DOCUMENT, {
    variables: {
      id: doc,
    },
    fetchPolicy: "no-cache",
  });

  if (loading) return <p className="font-body">cargando ...</p>;

  if (error) return <p className="font-body">error {error.message}</p>;

  const { getHistorialComprobanteByID } = data;

  if (getHistorialComprobanteByID) {
    return (
      <div className="w-full flex flex-col h-screen items-center justify-center font-body">
        <div className="flex flex-col md:flex-row w-full">
          <div className="w-full md:w-1/2 flex flex-col px-10 md:items-end">
            <span className="w-64 md:w-72 xl:w-96 font-semibold text-lg">
              Razón Social Emisor:
            </span>
            <span className="w-64 md:w-72 xl:w-96 text-xl whitespace-pre-wrap">
              {getHistorialComprobanteByID.sEmisorRazonSocial}
            </span>
            <span className="w-64 md:w-72 xl:w-96 font-semibold text-lg">
              RUC Emisor:
            </span>
            <span className="w-64 md:w-72 xl:w-96 text-xl">
              {getHistorialComprobanteByID.sEmisorDoi}
            </span>
            <span className="w-64 md:w-72 xl:w-96 font-semibold text-lg">
              Serie y Correlativo:
            </span>
            <span className="w-64 md:w-72 xl:w-96 text-xl">{`${getHistorialComprobanteByID.sDocSerie}-${getHistorialComprobanteByID.sDocCorrelativo}`}</span>
            <span className="w-64 md:w-72 xl:w-96 font-semibold text-lg">
              Monto:
            </span>
            <span className="w-64 md:w-72 xl:w-96 text-xl">{`${getHistorialComprobanteByID.sDocTipoMoneda
              } ${Number(getHistorialComprobanteByID.sTotal).toFixed(2)}`}</span>
          </div>
          <div className="w-full md:w-1/2 flex flex-col items-center px-10">
            <a
              className="focus:outline-none hidden lg:flex px-2 py-1 text-3xl text-white w-20 font-semibold text-center bg-color-2 my-4"
              href={`https://consulta.detoddo.com/pdf/${getHistorialComprobanteByID._id}`}
              target="_blank"
            >
              <span className="w-full text-center">PDF</span>
            </a>
            <div className="w-20">
              <ButtonPDF />
            </div>
            {getHistorialComprobanteByID.archXML ? (
              <a
                className="focus:outline-none px-2 py-1 text-3xl text-white w-20 font-semibold text-center bg-color-16 my-4"
                download={getHistorialComprobanteByID.archXML[0]?.nombre}
                href={getHistorialComprobanteByID.archXML[0]?.data}
              >
                XML
              </a>
            ) : null}
            {getHistorialComprobanteByID.archCDR ? (
              <a
                className="focus:outline-none px-2 py-1 text-3xl text-white w-20 font-semibold text-center bg-color-10 my-4"
                download={getHistorialComprobanteByID.archCDR[0]?.nombre}
                href={getHistorialComprobanteByID.archCDR[0]?.data}
              >
                CDR
              </a>
            ) : null}
          </div>
        </div>
        <span className="text-lg font-semibold text-color-11">Detoddo.com</span>
      </div>
    );
  } else {
    return <p className="font-body">no hay datos ...</p>;
  }
}

export default Consulta;
