import MyTicket from "./components/MyTicket";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { QUERY_GET_INFO_DOC } from "./graphql/Doc";

export default function Ticket() {
  let { doc } = useParams();

  const { data, loading, error } = useQuery(QUERY_GET_INFO_DOC, {
    variables: {
      id: doc,
    },
    fetchPolicy: "no-cache",
  });

  if (loading) return <p>cargando ...</p>;

  if (error) return <p>error {error.message}</p>;

  const { getHistorialComprobanteByIDxPDF_Ticket } = data;

  if (getHistorialComprobanteByIDxPDF_Ticket) {
    return <MyTicket data={getHistorialComprobanteByIDxPDF_Ticket} />;
  } else {
    return <p>no hay datos ...</p>;
  }
}
