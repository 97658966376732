import { gql } from '@apollo/client';

export const QUERY_GET_INFO_DOC = gql`
	query getHistorialComprobanteByIDxPDF_Ticket($id: ID) {
		getHistorialComprobanteByIDxPDF_Ticket(id: $id) {
			_id
			idPersona
			idOrden
			idTienda
			idCliente
			cutComprobante
			sEmisorDoi
			sEmisorRazonSocial
			sEmisorDireccionFiscal
			sEmisorCodigoAnexo
			sDocTipo
			codigo
			sDocSerie
			sDocCorrelativo
			sDocFechaEmision
			sDocCodigoNota
			sDocMotivoNota
			fechaVto
			sClienteTipoDoi
			sClienteDoi
			sClienteRazonSocial
			sClienteDireccionFiscal
			sTotalOpeGravado
			sTotalOpeInafecto
			sTotalOpeExonerado
			sTotalIsc
			sTotalIgv
			sTotal
			totalSaldo
			estadoSaldo
			sDocMontoLetras
			sDocTipoMoneda
			sDocCantidadItem
			DocRelacionadoDocId
			sDocRelacionadoTipoDoc
			sDocRelacionadoSerie
			sDocRelacionadoCorrelativo
			estadoEnvioFiscal
			sDetalleProducto {
				item
				idProducto
				idPresentacion
				descripcion
				cantidad
				unidadMedidaFiscal
				unidadMedidaDescripcion
				sku
				nombreProducto
				precioUnitario
				descuento
				valorUnitario
				itemGravado
				itemInafecto
				itemExonerado
				itemIsc
				itemIgv
				itemTotal
				itemPorcentajeIgv
				importeTotal
				isProducto
				addFreeRow
			}
			observacion
			usuarioReg
			sTipoCambio
			condicionPagoC
			ordenVenta
			logoTienda
			codigoQR
			emisorNombreComercial
			telefonos
			medioPago {
				idMedioPago
				idImagen
				medio
				grupo
				nombre
				moneda
				nroCuenta
				cci
				descripcion
				operador
				mostrarPortal
				mostrarEnComprobante
			}
			nombreDetraccion
			codigoDetraccion
			porcentajeDetraccion
			importeDetraccion
			cuotasComprobante {
				nombre
				montoMO
				moneda
				dias
				fecVencimiento
			}
			lema
    		tituloAdvertencia
    		advertencia
		}
	}
`;

export const QUERY_GET_DOCUMENT = gql`
	query getHistorialComprobanteByID($id: ID) {
		getHistorialComprobanteByID(id: $id) {
			_id
			idPersona
			idOrden
			idTienda
			idCliente
			sEmisorDoi
			sEmisorRazonSocial
			sEmisorDireccionFiscal
			sEmisorCodigoAnexo
			sDocTipo
			codigo
			sDocSerie
			sDocCorrelativo
			sDocFechaEmision
			sDocMotivoNota
			fechaVto
			sClienteTipoDoi
			sClienteDoi
			sClienteRazonSocial
			sClienteDireccionFiscal
			sTotalOpeGravado
			sTotalOpeInafecto
			sTotalOpeExonerado
			sTotalIsc
			sTotalIgv
			sTotal
			totalSaldo
			estadoSaldo
			sDocMontoLetras
			sDocTipoMoneda
			sDocCantidadItem
			DocRelacionadoDocId
			sDocRelacionadoTipoDoc
			sDocRelacionadoSerie
			sDocRelacionadoCorrelativo
			estadoEnvioFiscal
			usuarioReg
			archXML {
				nombre
				data
			}
			archCDR {
				nombre
				data
			}
		}
	}
`;

export const QUERY_GET_DOCUMENT_ID = gql`
	query getIdHistorialComprobanteConsulta($ruc: String, $tipoDoc: String, $serie: String, $correlativo: Int) {
		getIdHistorialComprobanteConsulta(ruc: $ruc, tipoDoc: $tipoDoc, serie: $serie, correlativo: $correlativo)
	}
`;

export const QUERY_GET_GUIA = gql`
	query getHistorialGuiaComprobanteByID($id: ID) {
		getHistorialGuiaComprobanteByID(id: $id) {
			_id
			idPersona
			idOrden
			idTienda
			idCliente
			cutComprobante
			emisorDoi
			emisorRazonSocial
			emisorDireccionFiscal
			docTipo
			docSerie
			docCorrelativo
			docFechaEmision
			docFechaInicioTraslado
			clienteTipoDoi
			clienteDoi
			clienteRazonSocial
			direccionPuntoPartida
			direccionPuntoLlegada
			docMontoLetras
			docTipoMoneda
			docCantidadItem
			motivo
			modalidad
			pesoBrutoTotal
			conductorChofer
			conductorPlaca
			conductorTipoDOI
			conductorDOI
			conductorLicencia
			transportistaRUC
			transportistaRazonSocial
			transportistaDireccion
			estadoEnvioFiscal
			estadoEnvioCorreo
			fechaEnvioCorreo
			correoEnviado
			idOrdenBaja
			fechaBaja
			departamentoPuntoPartida
			provinciaPuntoPartida
			distritoPuntoPartida
			departamentoPuntoLlegada
			provinciaPuntoLlegada
			distritoPuntoLlegada
			observacion
			detalleProducto {
				item
				idProducto
				idPresentacion
				descripcion
				cantidad
				unidadMedidaFiscal
				unidadMedidaDescripcion
				sku
				nombreProducto
				isProducto
				addFreeRow
			}
			logoTienda
		}
	}
`;


export const QUERY_GET_GUIA_TRANSPORTISTA = gql`
	query getHistorialGuiaComprobanteCompraByID($id: ID) {
		getHistorialGuiaComprobanteCompraByID(id: $id) {
			_id
			idPersona
			idOrden
			idTienda
			idCliente
			cutComprobante
			emisorDoi
			emisorRazonSocial
			emisorDireccionFiscal
			docTipo
			docSerie
			docCorrelativo
			docFechaEmision
			docFechaInicioTraslado
			clienteTipoDoi
			clienteDoi
			clienteRazonSocial
			direccionPuntoPartida
			direccionPuntoLlegada
			docMontoLetras
			docTipoMoneda
			docCantidadItem
			motivo
			modalidad
			pesoBrutoTotal
			conductorChofer
			conductorPlaca
			conductorTipoDOI
			conductorDOI
			conductorLicencia
			transportistaRUC
			transportistaRazonSocial
			transportistaDireccion
			estadoEnvioFiscal
			estadoEnvioCorreo
			fechaEnvioCorreo
			correoEnviado
			idOrdenBaja
			fechaBaja
			departamentoPuntoPartida
			provinciaPuntoPartida
			distritoPuntoPartida
			departamentoPuntoLlegada
			provinciaPuntoLlegada
			distritoPuntoLlegada
			detalleProducto {
				item
				idProducto
				idPresentacion
				descripcion
				cantidad
				unidadMedidaFiscal
				unidadMedidaDescripcion
				sku
				nombreProducto
				isProducto
				addFreeRow
			}
			logoTienda
		}
	}

	
`;
