import React from 'react';

import ReactDOM from 'react-dom';
import PDF from './PDF';
import Guia from './Guia';
import GuiaTransportista from './Guia_transportista';
import Ticket from './Ticket';
import Consulta from './Consulta';
import Busqueda from './Busqueda';
import './tailwind.css';
import './ticket.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import { ApolloClient, InMemoryCache } from '@apollo/client';
import { ApolloProvider } from '@apollo/client';

import { Helmet } from 'react-helmet';

const client = new ApolloClient({
	uri: process.env.REACT_APP_API,
	cache: new InMemoryCache()
});

ReactDOM.render(
	<ApolloProvider client={client}>
		<Helmet>
			<title>Detoddo | Consulta</title>
		</Helmet>
		<Router>
			<Switch>
				<Route path="/consulta/:doc">
					<Consulta />
				</Route>
				<Route path="/pdf/:doc">
					<PDF />
				</Route>
				<Route path="/ticket/:doc">
					<Ticket />
				</Route>
				<Route path="/guia/:doc">
					<Guia />
				</Route>
				<Route path="/guia_compra/:doc">
					<GuiaTransportista />
				</Route>
				<Route path="/">
					<Busqueda />
				</Route>
			</Switch>
		</Router>
	</ApolloProvider>,
	document.getElementById('root')
);

reportWebVitals();
