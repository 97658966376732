import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { QUERY_GET_INFO_DOC } from "../graphql/Doc";
import MyDocument from "./MyDocument";
import { PDFDownloadLink } from "@react-pdf/renderer";

export default function ButtonPDF({ label = "PDF" }) {
    const { doc } = useParams();

    const { data, loading, error } = useQuery(QUERY_GET_INFO_DOC, {
        variables: {
            id: doc,
        },
        fetchPolicy: "no-cache",
    });

    if (loading) return <p className="lg:hidden font-body">cargando ...</p>;

    if (error) return <p className="lg:hidden font-body">error {error.message}</p>;

    const { getHistorialComprobanteByIDxPDF_Ticket } = data;

    if (getHistorialComprobanteByIDxPDF_Ticket) {
        return (
            <PDFDownloadLink
                className="focus:outline-none px-2 py-1 flex w-full justify-center lg:hidden text-3xl text-white font-semibold text-center bg-color-2 my-4"
                document={<MyDocument data={getHistorialComprobanteByIDxPDF_Ticket} />}
                fileName={`${getHistorialComprobanteByIDxPDF_Ticket?.sDocSerie}-${getHistorialComprobanteByIDxPDF_Ticket?.sDocCorrelativo}-${getHistorialComprobanteByIDxPDF_Ticket?.sEmisorDoi}`}
            >
                {label}
            </PDFDownloadLink>
        );
    } else {
        return <p>No hay datos ...</p>;
    }
}
