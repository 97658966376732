import { StyleSheet } from '@react-pdf/renderer';

export const styles = StyleSheet.create({
	/* Generales */
	body: {
		paddingVertical: 20,
		paddingHorizontal: 25
	},
	row: {
		flexDirection: 'row'
	},
	col: {
		flexDirection: 'col'
	},
	/* -------------------------------- */
	/* Contenedores */
	infoTienda: {
		width: '75%',
		flexDirection: 'row'
	},
	infoDoc: {
		borderWidth: 1,
		borderStyle: 'solid',
		borderColor: '#DCDCDC',
		padding: 4,
		width: '25%',
		height: 50,
		flexGrow: 1,
		flexDirection: 'col',
		justifyContent: 'center'
	},
	detailDoc: {
		width: '100%',
		flexDirection: 'col',
		marginTop: 4
	},
	rowDetailDoc: {
		flexDirection: 'row',
		width: '100%',
		marginVertical: 2
	},
	rowFull: { flexDirection: 'row', width: '100%' },
	col50: { flexDirection: 'col', width: '50%' },
	col70: { flexDirection: 'col', width: '70%' },
	col33: { flexDirection: 'col', width: '33%' },
	col30: { flexDirection: 'col', width: '30%' },
	/* -------------------------------- */
	/* Estilos de texto */
	infoDocRUC: {
		fontSize: 9,
		textAlign: 'center',
		marginBottom: 3
	},
	infoDocTittle: {
		fontSize: 10,
		textAlign: 'center',
		textTransform: 'uppercase'
	},
	infoDocSerieCorrel: {
		fontSize: 9,
		textAlign: 'center',
		marginTop: 3
	},
	nombreTienda: {
		fontSize: 10,
		paddingHorizontal: 10,
		width: 288
	},
	direccionFiscal: {
		fontSize: 7,
		paddingHorizontal: 10,
		width: 350
	},
	telefonos: {
		fontSize: 7,
		paddingHorizontal: 10,
		width: 288
	},
	itemDetailDoc: {
		fontSize: 7,
		width: 80
	},
	separadorItemDetailDoc: {
		fontSize: 7
	},
	itemDetailDocData: {
		fontSize: 7,
		width: 445,
		paddingHorizontal: 5
	},
	itemDetailDocDataCol: {
		fontSize: 7,
		paddingHorizontal: 5
	},
	/* --------------------------------- */
	/* Estilos imágenes */
	imageTienda: {
		width: 50,
		height: 50,
		objectFit: 'cover',
		objectPosition: 'center',
		borderRadius: 32
	},
	imageQR: {
		width: 65,
		height: 65,
		objectFit: 'cover',
		objectPosition: 'center'
	},
	/* --------------------------------- */
	/* Grilla de productos */
	grilla: {
		flexDirection: 'col',
		borderLeftWidth: 1,
		borderLeftStyle: 'solid',
		borderLeftColor: '#DCDCDC',
		borderRightWidth: 1,
		borderRightStyle: 'solid',
		borderRightColor: '#DCDCDC',
		borderBottomtWidth: 1,
		borderBottomStyle: 'solid',
		borderBottomColor: '#DCDCDC',
		width: '100%'
	},
	headerGrilla: {
		marginTop: '4px',
		flexDirection: 'row',
		width: '100%',
		backgroundColor: '#CDCDCD',
		borderTopWidth: 1,
		borderTopStyle: 'solid',
		borderTopColor: '#DCDCDC',
		borderLeftWidth: 1,
		borderLeftStyle: 'solid',
		borderLeftColor: '#DCDCDC',
		borderRightWidth: 1,
		borderRightStyle: 'solid',
		borderRightColor: '#DCDCDC',
		borderBottomWidth: 1,
		borderBottomStyle: 'solid',
		borderBottomColor: '#DCDCDC'
	},
	headerCant: {
		fontSize: 7,
		width: '8%',
		borderRightWidth: 1,
		borderRightStyle: 'solid',
		borderRightColor: '#DCDCDC',
		paddingVertical: '3px',
		paddingHorizontal: '2px'
	},
	headerUm: {
		fontSize: 7,
		width: '8%',
		textAlign: 'center',
		borderRightWidth: 1,
		borderRightStyle: 'solid',
		borderRightColor: '#DCDCDC',
		paddingVertical: '3px',
		paddingHorizontal: '2px'
	},
	headerDescripcion: {
		fontSize: 7,
		width: '54%',
		borderRightWidth: 1,
		borderRightStyle: 'solid',
		borderRightColor: '#DCDCDC',
		paddingVertical: '3px',
		paddingHorizontal: '2px'
	},
	headerDescripcionGuia: {
		fontSize: 7,
		width: '84%',
		borderRightWidth: 1,
		borderRightStyle: 'solid',
		borderRightColor: '#DCDCDC',
		paddingVertical: '3px',
		paddingHorizontal: '2px'
	},
	headerPrecio: {
		fontSize: 7,
		width: '15%',
		textAlign: 'right',
		borderRightWidth: 1,
		borderRightStyle: 'solid',
		borderRightColor: '#DCDCDC',
		paddingVertical: '3px',
		paddingHorizontal: '2px'
	},
	headerTotal: {
		fontSize: 7,
		width: '15%',
		textAlign: 'right',
		paddingVertical: '3px',
		paddingHorizontal: '2px'
	},
	product: {
		flexDirection: 'row',
		width: '100%'
	},
	productAux: {
		flexDirection: 'row',
		width: '100%',
		color: 'white'
	},
	productCant: {
		fontSize: 7,
		width: '8%',
		borderRightWidth: 1,
		borderRightStyle: 'solid',
		borderRightColor: '#DCDCDC',
		paddingVertical: '3px',
		paddingHorizontal: '2px'
	},
	productUm: {
		fontSize: 7,
		width: '8%',
		textAlign: 'center',
		borderRightWidth: 1,
		borderRightStyle: 'solid',
		borderRightColor: '#DCDCDC',
		paddingVertical: '3px',
		paddingHorizontal: '2px'
	},
	productDescripcion: {
		fontSize: 7,
		width: '54%',
		borderRightWidth: 1,
		borderRightStyle: 'solid',
		borderRightColor: '#DCDCDC',
		paddingVertical: '3px',
		paddingHorizontal: '2px'
	},
	productDescripcionGuia: {
		fontSize: 7,
		width: '84%',
		borderRightWidth: 1,
		borderRightStyle: 'solid',
		borderRightColor: '#DCDCDC',
		paddingVertical: '3px',
		paddingHorizontal: '2px'
	},
	productPrecio: {
		fontSize: 7,
		width: '15%',
		textAlign: 'right',
		borderRightWidth: 1,
		borderRightStyle: 'solid',
		borderRightColor: '#DCDCDC',
		paddingVertical: '3px',
		paddingHorizontal: '2px'
	},
	productTotal: {
		fontSize: 7,
		width: '15%',
		textAlign: 'right',
		paddingVertical: '3px',
		paddingHorizontal: '2px'
	},
	rowTotalLetras: {
		flexDirection: 'row',
		width: '100%',
		borderTopWidth: 1,
		borderTopStyle: 'solid',
		borderTopColor: '#DCDCDC',
		paddingVertical: '3px',
		paddingHorizontal: '2px'
	},
	productTotalLetras: {
		fontSize: 7
	},
	footerGrilla: {
		flexDirection: 'row',
		width: '100%',
		borderTopWidth: 1,
		borderTopStyle: 'solid',
		borderTopColor: '#DCDCDC',
		borderBottomWidth: 1,
		borderBottomStyle: 'solid',
		borderBottomColor: '#DCDCDC'
	},
	qrGrilla: {
		width: '14%',
		paddingVertical: '3px',
		paddingHorizontal: '2px'
	},
	detailGrilla: {
		width: '56%',
		paddingVertical: '3px',
		paddingHorizontal: '2px'
	},
	detailGrillaGuia: {
		width: '100%',
		paddingVertical: '3px',
		paddingHorizontal: '2px'
	},
	textDetailGrilla: {
		fontSize: 7,
		paddingHorizontal: '2px'
	},
	importesGrilla: {
		width: '30%'
	},
	rowImporteGrilla: {
		flexDirection: 'row',
		alignItems: 'center',
		width: '100%'
	},
	footerText: {
		fontSize: 6,
		width: 288,
		alignItems: 'left'
	},
	footerTextCuentas: {
		fontSize: 7,
		width: 288,
		alignItems: 'left'
	},
	footerTextAdvertencia: {
		fontSize: 6,
		width: '100%',
		alignItems: 'stretch',				
	},
	footerTextBold: {
		fontSize: 8,
		width: 288,
		alignItems: 'left',
		fontWeight: "bold"
	},
	tipoImporte: {
		width: '50%',
		fontSize: 7,
		paddingVertical: '2px',
		paddingHorizontal: '2px'
	},
	valorTipoImporte: {
		width: '50%',
		fontSize: 7,
		paddingVertical: '2px',
		paddingHorizontal: '2px',
		textAlign: 'right'
	}
	/* --------------------------------- */
	/*   pageNumber: {
      position: "absolute",
      fontSize: 12,
      bottom: 30,
      left: 0,
      right: 0,
      textAlign: "center",
      color: "grey",
    }, */
});
