import { PDFViewer } from '@react-pdf/renderer';
import MyDocument from './components/MyDocument';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { QUERY_GET_INFO_DOC } from './graphql/Doc';
import ButtonPDF from './components/ButtonPDF';

function PDF() {
	const { doc } = useParams();

	const { data, loading, error } = useQuery(QUERY_GET_INFO_DOC, {
		variables: {
			id: doc
		},
		fetchPolicy: 'no-cache'
	});

	if (loading) return <p>cargando ...</p>;

	if (error) return <p>error {error.message}</p>;

	const { getHistorialComprobanteByIDxPDF_Ticket } = data;

	if (getHistorialComprobanteByIDxPDF_Ticket) {
		return (
			<div className="w-screen h-screen">
				<PDFViewer className="w-full h-full hidden lg:flex">
					<MyDocument data={getHistorialComprobanteByIDxPDF_Ticket} />
				</PDFViewer>
				<div className="w-full h-full flex flex-row justify-center items-center">
					<div className="w-56">
						<ButtonPDF label="Descargar PDF" />
					</div>
				</div>
			</div>
		);
	} else {
		return <p>no hay datos ...</p>;
	}
}

export default PDF;
