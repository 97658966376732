import { Page, Image, Text, View, Document } from "@react-pdf/renderer";
import { styles } from "../StylesPDF";

export default function MyDocument({ data = null }) {
  const productsInDoc = data?.sDetalleProducto.length;

  let productsAuxArray = [];
  if (productsInDoc <= 10) {
    for (var i = 0; i <= 10 - productsInDoc; i++) {
      productsAuxArray = [...productsAuxArray, i];
    }
    }

  let comprobanteList = []; 
  
  let countItemsMedPagos = 4;

  if(Number(data.importeDetraccion) != 0){
    countItemsMedPagos = 4;
  }

  if(data.medioPago != null){
    let formasAux = [];
    data.medioPago?.map((item, index) => {
      if(formasAux.length < countItemsMedPagos){ 
        if(item.mostrarEnComprobante){
          formasAux = [...formasAux, {
            idMedioPago: item.idMedioPago,
            nroCuenta: item.nroCuenta,
            descripcion: item.nombre + " " + item.descripcion            
          }];
        }
      }
      
    });

    comprobanteList = formasAux;      
    if(data.cuotasComprobante != null && data.importeDetraccion != null){
      data?.cuotasComprobante.map((item, index) => {{
        item.montoMO = Number(item.montoMO) - Number(data.importeDetraccion);
      }});
    }

    if(data.telefonos == null){
      data.telefonos = "-";
    }
  }

  return (
    <Document
      title="nuevo pdf"
      author="detoddo.com"
      subject="nuevo pdf"
      keywords="nuevo pdf"
      file="nuevo pdf"
    >
      <Page style={styles.body} size="A4">
        <View style={styles.row}>
          <View style={styles.infoTienda}>
            <Image
              src={`https://backend.detoddo.com/imagen/${data.logoTienda}`}
              style={styles.imageTienda}
            />
            <View style={styles.datosTiendaHeader}>
              <Text style={styles.nombreTienda}>   </Text>
              <Text style={styles.nombreTienda}>{data.sEmisorRazonSocial}</Text>
              <Text style={styles.direccionFiscal}>
                {`Dirección Fiscal:  ${data.sEmisorDireccionFiscal}`}
              </Text>
              <Text style={styles.telefonos}>
                {`Teléfonos:  ${data.telefonos}`}
              </Text>
            </View>
          </View>
          <View style={styles.infoDoc}>
            <Text style={styles.infoDocRUC}>{`RUC: ${data.sEmisorDoi}`}</Text>
            <Text style={styles.infoDocTittle}>{data.sDocTipo}</Text>
            {data.sDocTipo !== "Nota de Venta" ? (
              <Text style={styles.infoDocTittle}>ELECTRÓNICA</Text>
            ) : null}

            <Text
              style={styles.infoDocSerieCorrel}
            >{`${data.sDocSerie} - ${data.sDocCorrelativo}`}</Text>
          </View>
        </View>
        <View style={styles.detailDoc}>
          <View style={styles.rowDetailDoc}>
            <Text style={styles.itemDetailDoc}>Cliente</Text>
            <Text style={styles.separadorItemDetailDoc}>:</Text>
            <Text style={styles.itemDetailDocData}>
              {data.sClienteRazonSocial}
            </Text>
          </View>
          <View style={styles.rowDetailDoc}>
            <Text style={styles.itemDetailDoc}>{data.sClienteTipoDoi}</Text>
            <Text style={styles.separadorItemDetailDoc}>:</Text>
            <Text style={styles.itemDetailDocData}>{data.sClienteDoi}</Text>
          </View>
          <View style={styles.rowDetailDoc}>
            <Text style={styles.itemDetailDoc}>Dirección</Text>
            <Text style={styles.separadorItemDetailDoc}>:</Text>
            <Text style={styles.itemDetailDocData}>
              {data.sClienteDireccionFiscal}
            </Text>
          </View>
          <View style={styles.rowFull}>
            <View style={styles.col33}>
              <View style={styles.rowDetailDoc}>
                <Text style={styles.itemDetailDoc}>Fecha Emisión</Text>
                <Text style={styles.separadorItemDetailDoc}>:</Text>
                <Text style={styles.itemDetailDocDataCol}>
                  {new Date(data.sDocFechaEmision).toLocaleDateString()}
                </Text>
              </View>
              <View style={styles.rowDetailDoc}>
                <Text style={styles.itemDetailDoc}>Fecha Vencimiento</Text>
                <Text style={styles.separadorItemDetailDoc}>:</Text>
                <Text style={styles.itemDetailDocDataCol}>
                  {new Date(data.fechaVto).toLocaleDateString()}
                </Text>
              </View>
            </View>
            <View style={styles.col33}>
              <View style={styles.rowDetailDoc}>
                <Text style={styles.itemDetailDoc}>Moneda</Text>
                <Text style={styles.separadorItemDetailDoc}>:</Text>
                <Text style={styles.itemDetailDocDataCol}>
                  {data.sDocTipoMoneda}
                </Text>
              </View>
              <View style={styles.rowDetailDoc}>
                <Text style={styles.itemDetailDoc}>Tipo Cambio</Text>
                <Text style={styles.separadorItemDetailDoc}>:</Text>
                <Text style={styles.itemDetailDocDataCol}>
                  {Number(data.sTipoCambio).toFixed(3)}
                </Text>
              </View>
            </View>
            <View style={styles.col33}>
              <View style={styles.rowDetailDoc}>
                <Text style={styles.itemDetailDoc}>Condición de pago</Text>
                <Text style={styles.separadorItemDetailDoc}>:</Text>
                <Text style={styles.itemDetailDocDataCol}>
                  {data.condicionPagoC}
                </Text>
              </View>
              {data.sDocTipo === "Nota de Crédito F." || data.sDocTipo === "Nota de Crédito B." ? (
              <View style={styles.rowDetailDoc}>
                <Text style={styles.itemDetailDoc}>Doc. Modifica</Text>
                <Text style={styles.separadorItemDetailDoc}>:</Text>
                <Text style={styles.itemDetailDocDataCol}>
                  {data.sDocRelacionadoSerie} - {data.sDocRelacionadoCorrelativo}
                </Text>
              </View>
            ) : 
            <View style={styles.rowDetailDoc}>
                <Text style={styles.itemDetailDoc}>Orden Compra</Text>
                <Text style={styles.separadorItemDetailDoc}>:</Text>
                <Text style={styles.itemDetailDocDataCol}>
                  {data.ordenVenta}
                </Text>
              </View>}              
            </View>
          </View>
        </View>
        <View
          style={styles.headerGrilla}
          fixed={productsInDoc > 33 ? true : false}
        >
          <Text style={styles.headerCant}>CANT.</Text>
          <Text style={styles.headerDescripcion}>DESCRIPCIÓN</Text>
          <Text style={styles.headerUm}>U.M.</Text>
          <Text style={styles.headerPrecio}>PRECIO UNIT.</Text>
          <Text style={styles.headerTotal}>TOTAL</Text>
        </View>
        <View style={styles.grilla}>
          {/* Productos */}
          {data.sDetalleProducto.map((item, index) => {
            return (
              <View style={styles.product} key={index}>
                <Text style={styles.productCant}>
                  {item.isProducto || item.isProducto == null
                    ? Number(item.cantidad).toFixed(2)
                    : ""}
                </Text>
                <Text style={styles.productDescripcion}>
                  {item.isProducto || item.isProducto == null
                    ? `${item.sku} - ${item.nombreProducto} ${item.descripcion}`
                    : item.descripcion}
                </Text>
                <Text style={styles.productUm}>
                  {item.isProducto || item.isProducto == null
                    ? item.unidadMedidaDescripcion
                    : ""}
                </Text>
                <Text style={styles.productPrecio}>
                  {item.isProducto || item.isProducto == null
                    ? item.precioUnitario
                    : ""}
                </Text>
                <Text style={styles.productTotal}>
                  {item.isProducto || item.isProducto == null
                    ? item.importeTotal
                    : ""}
                </Text>
              </View>
            );
          })}
          {
          (data.cuotasComprobante) ? (
            data?.cuotasComprobante.map((item, index) => {
              return (
                <View style={styles.product} key={index}>
                  <Text style={styles.productCant}>                 
                  </Text>
                  <Text style={styles.productDescripcion}>
                    {item.nombre} | {item.fecVencimiento} | {item.montoMO}
                  </Text>
                  <Text style={styles.productUm}>                  
                  </Text>
                  <Text style={styles.productPrecio}>                  
                  </Text>
                  <Text style={styles.productTotal}>                  
                  </Text>
                </View>
              );
            })
          ) : null}        
          
          {productsInDoc < 26
            ? productsAuxArray?.map((value) => {
                return (
                  <View style={styles.productAux} key={value}>
                    <Text style={styles.productCant}>1</Text>
                    <Text style={styles.productDescripcion}>-</Text>
                    <Text style={styles.productUm}>-</Text>
                    <Text style={styles.productPrecio}>-</Text>
                    <Text style={styles.productTotal}>-</Text>
                  </View>
                );
              })
            : null}
          {/* -------------------------------- */}
          <View style={styles.rowTotalLetras}>
            <Text style={styles.productTotalLetras}>
              {`SON: ${data.sDocMontoLetras}`}
            </Text>
          </View>
          <View style={styles.footerGrilla}>
            <View style={styles.qrGrilla}>
              <Image src={data.codigoQR} style={styles.imageQR} />
            </View>
            <View style={styles.detailGrilla}>              
              {/* *********************** */} 
              {
                (data.lema != "") ? (
                  <>
                    <View style={styles.rowImporteGrilla}>
                      <Text style={styles.footerTextBold}>{data.lema}</Text>                
                    </View>                    
                  </>
                ) : null
              }
              {/* *********************** */}              
              {
              (Number(data.importeDetraccion) != 0) ? (
              <>
                <View style={styles.rowImporteGrilla}>
                  <Text style={styles.footerText}>AFECTO DETRACCION: {data.nombreDetraccion}</Text>                
                </View>
                <View style={styles.rowImporteGrilla}>
                  <Text style={styles.footerText}>{data.porcentajeDetraccion} % S/ {Number(data.importeDetraccion).toFixed(2)} {(data.cuotasComprobante?.length > 0) ? "| IMPORTE NETO A PAGAR: S/ " + (Number(data.sTotal) - Number(data.importeDetraccion)) : ""}</Text>                
                </View>                
              </>
              ): null}
              {/* *********************** */}              
              {
              (comprobanteList.length > 0) ? (
              <>                
                {/* <View style={styles.rowImporteGrilla}>
                  <Text style={styles.footerTextCuentas}>CUENTAS BANCARIAS</Text>                
                </View> */}

                {comprobanteList?.map((item, index) => {
                  return (
                    <View style={styles.rowImporteGrilla}>
                      <Text style={styles.footerTextCuentas}> {item.descripcion}  : {item.nroCuenta}</Text>                    
                    </View>                  
                  );
                 })}
              </>
              ): null}
              
              <View style={styles.rowImporteGrilla}>
                  <Text style={styles.footerTextCuentas}>Consulta tu comprobante:   https://consulta.detoddo.com/</Text>                
              </View>

              
              
              
            
              {/* *********************** */}
            </View>
            <View style={styles.importesGrilla}>
              <View style={styles.rowImporteGrilla}>
                <Text style={styles.tipoImporte}>OPE. GRAVADA</Text>
                <Text style={styles.valorTipoImporte}>
                  {Number(data.sTotalOpeGravado).toFixed(2)}
                </Text>
              </View>
              <View style={styles.rowImporteGrilla}>
                <Text style={styles.tipoImporte}>OPE. EXONERADA</Text>
                <Text style={styles.valorTipoImporte}>
                  {Number(data.sTotalOpeExonerado).toFixed(2)}
                </Text>
              </View>
              <View style={styles.rowImporteGrilla}>
                <Text style={styles.tipoImporte}>OPE. INAFECTO</Text>
                <Text style={styles.valorTipoImporte}>
                  {Number(data.sTotalOpeInafecto).toFixed(2)}
                </Text>
              </View>
              <View style={styles.rowImporteGrilla}>
                <Text style={styles.tipoImporte}>ISC</Text>
                <Text style={styles.valorTipoImporte}>
                  {Number(data.sTotalIsc).toFixed(2)}
                </Text>
              </View>
              <View style={styles.rowImporteGrilla}>
                <Text style={styles.tipoImporte}>IGV</Text>
                <Text style={styles.valorTipoImporte}>
                  {Number(data.sTotalIgv).toFixed(2)}
                </Text>
              </View>
              <View style={styles.rowImporteGrilla}>
                <Text style={styles.tipoImporte}>TOTAL</Text>
                <Text style={styles.valorTipoImporte}>
                  {Number(data.sTotal).toFixed(2)}
                </Text>
              </View>
            </View>
          </View>
        </View>
        {/* <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        /> */}
        {
              (data.tituloAdvertencia !== null) ? (
              <>                
                <View>
                  <View style={styles.rowImporteGrilla}>
                    <Text style={styles.footerTextAdvertencia}>{data.tituloAdvertencia} {data.advertencia} </Text>                                         
                  </View>
                </View>
              </>
        ): null}
        
      </Page>
    </Document>
  );
}
