import { Page, Image, Text, View, Document } from "@react-pdf/renderer";
import { styles } from "../StylesPDF";

export default function MyDocument({ data = null }) {
  const productsInDoc = data?.detalleProducto.length;


  let productsAuxArray = [];
  if (productsInDoc <= 10) {
    for (var i = 0; i <= 10 - productsInDoc; i++) {
      productsAuxArray = [...productsAuxArray, i];
    }
  }

  return (
    <Document
      title="nuevo guia"
      author="detoddo.com"
      subject="nuevo guia"
      keywords="nuevo guia"
      file="nuevo guia"
    >
      <Page style={styles.body} size="A4">
        <View style={styles.row}>
          <View style={styles.infoTienda}>
            <Image
              src={`https://backend.detoddo.com/imagen/${data.logoTienda}`}
              style={styles.imageTienda}
            />
            <View style={styles.datosTiendaHeader}>
              <Text style={styles.nombreTienda}>{data.emisorRazonSocial}</Text>
              <Text style={styles.direccionFiscal}>
                {`Dirección Fiscal:  ${data.emisorDireccionFiscal}`}
              </Text>             
            </View>
          </View>
          <View style={styles.infoDoc}>
            <Text style={styles.infoDocRUC}>{`RUC: ${data.emisorDoi}`}</Text>
            <Text style={styles.infoDocTittle}>GUIA REMITENTE</Text>
            
              <Text style={styles.infoDocTittle}>ELECTRÓNICA</Text>
            

            <Text
              style={styles.infoDocSerieCorrel}
            >{`${data.docSerie} - ${data.docCorrelativo}`}</Text>
          </View>
        </View>
        <View style={styles.detailDoc}>
        <View style={styles.rowDetailDoc}>
            <Text style={styles.itemDetailDoc}>P. Partida</Text>
            <Text style={styles.separadorItemDetailDoc}>:</Text>
            <Text style={styles.itemDetailDocData}>
              {data.direccionPuntoPartida}
            </Text>
          </View>
          <View style={styles.rowDetailDoc}>
            <Text style={styles.itemDetailDoc}>Cliente</Text>
            <Text style={styles.separadorItemDetailDoc}>:</Text>
            <Text style={styles.itemDetailDocData}>
              {data.clienteRazonSocial}
            </Text>
          </View>
          <View style={styles.rowDetailDoc}>
            <Text style={styles.itemDetailDoc}>{data.clienteTipoDoi}</Text>
            <Text style={styles.separadorItemDetailDoc}>:</Text>
            <Text style={styles.itemDetailDocData}>{data.clienteDoi}</Text>
          </View>          
          <View style={styles.rowDetailDoc}>
            <Text style={styles.itemDetailDoc}>P. Llegada</Text>
            <Text style={styles.separadorItemDetailDoc}>:</Text>
            <Text style={styles.itemDetailDocData}>
              {data.direccionPuntoLlegada}
            </Text>
          </View>
          <View style={styles.rowFull}>
            <View style={styles.col33}>
              <View style={styles.rowDetailDoc}>
                <Text style={styles.itemDetailDoc}>Fecha Emisión</Text>
                <Text style={styles.separadorItemDetailDoc}>:</Text>
                <Text style={styles.itemDetailDocDataCol}>
                  {new Date(data.docFechaEmision).toLocaleDateString()}
                </Text>
              </View>
              <View style={styles.rowDetailDoc}>
                <Text style={styles.itemDetailDoc}>Fecha Traslado</Text>
                <Text style={styles.separadorItemDetailDoc}>:</Text>
                <Text style={styles.itemDetailDocDataCol}>
                  {new Date(data.docFechaInicioTraslado).toLocaleDateString()}
                </Text>
              </View>
            </View>
            <View style={styles.col33}>
              <View style={styles.rowDetailDoc}>
                <Text style={styles.itemDetailDoc}>Motivo</Text>
                <Text style={styles.separadorItemDetailDoc}>:</Text>
                <Text style={styles.itemDetailDocDataCol}>
                {data.motivo}
                </Text>
              </View>
              <View style={styles.rowDetailDoc}>
                <Text style={styles.itemDetailDoc}>Modalidad</Text>
                <Text style={styles.separadorItemDetailDoc}>:</Text>
                <Text style={styles.itemDetailDocDataCol}>
                {data.modalidad}
                </Text>
              </View>
            </View>
            <View style={styles.col33}>
              <View style={styles.rowDetailDoc}>
                <Text style={styles.itemDetailDoc}>Peso Bruto</Text>
                <Text style={styles.separadorItemDetailDoc}>:</Text>
                <Text style={styles.itemDetailDocDataCol}>
                {data.pesoBrutoTotal}
                </Text>
              </View>
              <View style={styles.rowDetailDoc}>
                <Text style={styles.itemDetailDoc}>Cant. Items</Text>
                <Text style={styles.separadorItemDetailDoc}>:</Text>
                <Text style={styles.itemDetailDocDataCol}>
                {data.docCantidadItem}
                </Text>
              </View>
            </View>
          </View>
        </View>
        <View
          style={styles.headerGrilla}
          fixed={productsInDoc > 33 ? true : false}
        >
          
          <Text style={styles.headerDescripcionGuia}>DESCRIPCIÓN</Text>
          <Text style={styles.headerUm}>U.M.</Text>          
          <Text style={styles.headerCant}>CANT.</Text>
        </View>
        <View style={styles.grilla}>
          {/* Productos */}
          {data.detalleProducto.map((item, index) => {
            return (
              <View style={styles.product} key={index}>
                
                <Text style={styles.productDescripcionGuia}>
                  {item.isProducto || item.isProducto == null
                    ? `${item.sku} - ${item.nombreProducto} ${item.descripcion}`
                    : item.descripcion}
                </Text>
                <Text style={styles.productUm}>
                  {item.isProducto || item.isProducto == null
                    ? item.unidadMedidaDescripcion
                    : ""}
                </Text>
                <Text style={styles.productCant}>
                  {item.isProducto || item.isProducto == null
                    ? Number(item.cantidad).toFixed(2)
                    : ""}
                </Text>
              </View>
            );
          })}
          {productsInDoc < 26
            ? productsAuxArray?.map((value) => {
                return (
                  <View style={styles.productAux} key={value}>                    
                    <Text style={styles.productDescripcionGuia}>-</Text>
                    <Text style={styles.productUm}>-</Text>
                    <Text style={styles.productCant}>1</Text>
                  </View>
                );
              })
            : null}
          {/* -------------------------------- */}
          <View style={styles.rowTotalLetras}>
            <Text style={styles.productTotalLetras}>
              
            </Text>
          </View>
          <View style={styles.footerGrilla}>
           
            <View style={styles.detailGrilla}>
            <View style={styles.rowImporteGrilla}>
                <Text style={styles.tipoImporte}>DATOS DEL TRANSPORTISTA</Text>
                <Text style={styles.tipoImporte}>                  
                </Text>
              </View>                   
              <View style={styles.rowFull}>
            <View style={styles.col70}>
              <View style={styles.rowDetailDoc}>
                <Text style={styles.itemDetailDoc}>Razon Social</Text>
                <Text style={styles.separadorItemDetailDoc}>:</Text>
                <Text style={styles.itemDetailDocDataCol}>
                {data.transportistaRazonSocial}  
                </Text>
              </View>
              <View style={styles.rowDetailDoc}>
                <Text style={styles.itemDetailDoc}>Chofer</Text>
                <Text style={styles.separadorItemDetailDoc}>:</Text>
                <Text style={styles.itemDetailDocDataCol}>
                {data.conductorChofer}    
                </Text>
              </View>
            </View>
            <View style={styles.col33}>
              <View style={styles.rowDetailDoc}>
                <Text style={styles.itemDetailDoc}>Placa</Text>
                <Text style={styles.separadorItemDetailDoc}>:</Text>
                <Text style={styles.itemDetailDocDataCol}>
                {data.conductorPlaca}  
                </Text>
              </View>
              <View style={styles.rowDetailDoc}>
                <Text style={styles.itemDetailDoc}>Licencia</Text>
                <Text style={styles.separadorItemDetailDoc}>:</Text>
                <Text style={styles.itemDetailDocDataCol}>
                {data.conductorLicencia}     
                </Text>
              </View>
            </View>            
          </View>
            </View>
            
          </View>
        </View>
        {/* <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        /> */}
      </Page>
    </Document>
  );
}
