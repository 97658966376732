// Create Document Component
export default function MyDocument({ data = null }) {
  return (
    <>
      <div className="hidden-print w-80 flex-row justify-center text-center mb-5 mt-5">
        <button
          className="hidden-print bg-gray-300 px-2 py-1 focus:outline-none border border-gray-400"
          onClick={() => window.print()}
        >
          Imprimir
        </button>
      </div>
      <div className="flex flex-col w-80 font-normal">
        {/* cabecera */}
        <div className="flex flex-col w-full mt-4">
          <div className="w-full flex justify-center">
            <img
              className="object-cover object-center h-20 w-20 rounded-full"
              src={`https://backend.detoddo.com/imagen/${data.logoTienda}`}
              alt="logo de la tienda"
            />
          </div>
          <span className="w-full text-center whitespace-pre-wrap font-bold text-sm mt-4">
            {data.sEmisorRazonSocial}
          </span>
          <span className="w-full text-center whitespace-pre-wrap text-sm">
            {data.sEmisorDireccionFiscal}
          </span>
          <span className="w-full text-center whitespace-pre-wrap text-sm">
            {`RUC: ${data.sEmisorDoi}`}
          </span>
          <span className="w-full text-center">
            -------------------------------------------------
          </span>
        </div>
        {/* ---------------------- */}
        {/* cliente */}
        <div className="flex flex-col w-full">
          <span className="w-full text-sm whitespace-pre-wrap">{`Cliente: ${data.sClienteRazonSocial}`}</span>
          <span className="w-full text-sm">{`RUC: ${data.sClienteDoi}`}</span>
          <span className="w-full text-sm">{`Dirección: ${data.sClienteDireccionFiscal}`}</span>
          <span className="w-full text-center">
            -------------------------------------------------
          </span>
        </div>
        {/* ---------------------- */}
        {/* Documento */}
        <div className="flex flex-col w-full">
          <span className="w-full text-center text-sm uppercase">
            {`${data.sDocTipo} ELECTRÓNICA`}
          </span>
          <span className="w-full text-center text-sm">{`${data.sDocSerie} - ${data.sDocCorrelativo}`}</span>
          <span className="w-full text-sm mt-2">{`Fecha emisión: ${new Date(
            data.sDocFechaEmision
          ).toLocaleDateString()}`}</span>
          <span className="w-full text-sm">{`Fecha vencimiento: ${new Date(
            data.fechaVto
          ).toLocaleDateString()}`}</span>
          <span className="w-full text-sm">{`Moneda: ${data.sDocTipoMoneda}`}</span>
          <span className="w-full text-center">
            -------------------------------------------------
          </span>
        </div>
        {/* ---------------------- */}
        {/* Productos */}
        <div className="flex flex-col w-full border-b-2 border-black">
          <div className="flex flex-row w-full border-b-2 border-black">
            <span className="text-sm font-bold w-40">Producto</span>
            <span className="text-sm font-bold w-10 text-right">Cant.</span>
            <span className="text-sm font-bold w-10 text-right">.Unit.</span>
            <span className="text-sm font-bold w-8 text-right">Dcto.</span>
            <span className="text-sm font-bold w-12 text-right">Total</span>
          </div>
          {data.sDetalleProducto?.map((item, index) => {
            return (
              <div className="flex flex-row w-full" key={index}>
                <span className="text-sm w-40 whitespace-pre-wrap">
                  {item.isProducto || item.isProducto == null
                    ? `${item.sku} - ${item.nombreProducto} ${item.descripcion}`
                    : item.descripcion}
                </span>
                <span className="text-sm w-10 text-right">
                  {item.isProducto || item.isProducto == null
                    ? Number(item.cantidad).toFixed(2)
                    : ""}
                </span>
                <span className="text-sm w-10 text-right">
                  {item.isProducto || item.isProducto == null
                    ? Number(item.precioUnitario).toFixed(2)
                    : ""}
                </span>
                <span className="text-sm w-8 text-right">
                  {item.isProducto || item.isProducto == null
                    ? Number(item.descuento).toFixed(2)
                    : ""}
                </span>
                <span className="text-sm w-12 text-right">
                  {item.isProducto || item.isProducto == null
                    ? Number(item.importeTotal).toFixed(2)
                    : ""}
                </span>
              </div>
            );
          })}
        </div>
        {/* ---------------------- */}
        {/* importes */}
        <div className="flex flex-col w-full mt-2">
          <div className="flex flex-row justify-end">
            <span className="text-sm w-32 text-right">OPE. GRAVADA:</span>
            <span className="text-sm w-20 text-right">
              {Number(data.sTotalOpeGravado).toFixed(2)}
            </span>
          </div>
          <div className="flex flex-row justify-end">
            <span className="text-sm w-32 text-right">OPE. EXONERADA:</span>
            <span className="text-sm w-20 text-right">
              {Number(data.sTotalOpeExonerado).toFixed(2)}
            </span>
          </div>
          <div className="flex flex-row justify-end">
            <span className="text-sm w-32 text-right">OPE. INAFECTO:</span>
            <span className="text-sm w-20 text-right">
              {Number(data.sTotalOpeInafecto).toFixed(2)}
            </span>
          </div>
          <div className="flex flex-row justify-end">
            <span className="text-sm w-32 text-right">ISC:</span>
            <span className="text-sm w-20 text-right">
              {Number(data.sTotalIsc).toFixed(2)}
            </span>
          </div>
          <div className="flex flex-row justify-end">
            <span className="text-sm w-32 text-right">IGV:</span>
            <span className="text-sm w-20 text-right">
              {Number(data.sTotalIgv).toFixed(2)}
            </span>
          </div>
          <div className="flex flex-row justify-end">
            <span className="text-sm w-32 text-right font-bold">TOTAL:</span>
            <span className="text-sm w-20 text-right font-bold">
              {Number(data.sTotal).toFixed(2)}
            </span>
          </div>
          <span className="w-full text-center">
            -------------------------------------------------
          </span>
        </div>
        {/* ---------------------- */}
        {/* cherry */}
        <div className="flex flex-col w-full">
          <span className="text-sm w-full text-center">
            Gracias por usar Detoddo.com
          </span>
          <span className="w-full text-center">
            -------------------------------------------------
          </span>
        </div>
        {/* ---------------------- */}
        {/* QR */}
        <div className="flex flex-col items-center my-4">
          <img
            className="object-cover object-center w-20 h-20"
            src={data.codigoQR}
            alt="QR del documento"
          />
        </div>
        {/* ---------------------- */}
      </div>
      <div className="hidden-print w-80 flex-row justify-center text-center mb-20 mt-5">
        <button
          className="hidden-print bg-gray-300 px-2 py-1 focus:outline-none border border-gray-400"
          onClick={() => window.print()}
        >
          Imprimir
        </button>
      </div>
    </>
  );
}
